<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP CLIENT EDIT

type    : view

uses    : header-view
          main-container
          main-card

route   : /clients/edit/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-client-edit">
    <header-view
      v-if="client"
      title="Edit Client"
      :id="'CLIENT-' + client.number.toString().padStart(5, '0')"
      :previous_page="'/clients/view/' + client.uuid"
      :btnOptions="[
        {
          name: 'Save',
          action: handleSubmit,
          btnColor: 'button-primary',
        },
      ]" />
    <main-container>
      <main-card>
        <v-form
          v-if="client"
          ref="form"
          @submit.prevent="handleSubmit"
          id="clientForm">
          <v-row>
            <v-col :cols="12" :sm="6">
              <!-- Note: user cannot edit client name at this time
              In the future, we can add checks to be able to update this 
              in our database and in quickbooks, but at this point due to 
              the uniqueness requirements, it is disabled. 
              TODO: decide which fields will be updated in quickbooks if 
              linked qb customer exits -->
              <v-text-field
                :disabled="true"
                id="client_name"
                v-model="client.client_name"
                label="Client Name" />
              <v-select
                id="client_type"
                v-model="client.client_type"
                :items="possible_client_types"
                label="Client Type"
                :rules="validate_client_type" />
              <v-select
                id="client_status"
                v-model="client.client_status"
                :items="possible_client_status"
                label="Client Status"
                :rules="validate_client_status" />
            </v-col>
            <v-col :cols="12" :sm="6">
              <v-text-field
                id="contact_name"
                v-model="client.contact_name"
                label="Contact Name"
                :rules="validate_contact_name" />
              <v-text-field
                id="contact_phone"
                v-model="client.contact_phone"
                label="Contact Phone Number"
                :rules="validate_contact_phone" />
              <!-- Note: user cannot edit email at this time
              In the future, we can add checks to be able to update this 
              in our database and in quickbooks, but at this point due to 
              the uniqueness requirements, it is disabled
              TODO: decide which fields will be updated in quickbooks if 
              linked qb customer exits -->
              <v-text-field
                :disabled="true"
                id="contact_email"
                v-model="client.contact_email"
                label="Contact Email Address" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                id="address"
                v-model="client.address"
                label="Address"
                :rules="validate_address" />
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="6" :md="3">
              <v-text-field
                id="city"
                v-model="client.city"
                label="City"
                :rules="validate_city" />
            </v-col>
            <v-col :cols="12" :sm="6" :md="3">
              <v-text-field
                id="state"
                v-model="client.state"
                label="State"
                :rules="validate_state" />
            </v-col>
            <v-col :cols="12" :sm="6" :md="3">
              <v-text-field
                id="zip_code"
                v-model="client.zip_code"
                label="Postal Code"
                :rules="validate_zip_code" />
            </v-col>
            <v-col :cols="12" :sm="6" :md="3">
              <v-text-field
                id="country"
                v-model="client.country"
                label="Country"
                :rules="validate_country" />
            </v-col>
          </v-row>
        </v-form>
      </main-card>
    </main-container>
    <simple-dialog-template
      :open="errorDialog"
      dialogTitle="Error Updating Client"
      :dialogText="errorMessage"
      dialogButtonOne="OK"
      @buttonOne="errorDialog = false" />
  </div>
</template>

<script>
  // components
  import MainContainer from '@/components/main-container';
  import HeaderView from '@/components/header-view';
  import MainCard from '@/components/main-card';
  import SimpleDialogTemplate from '@/components/simple-dialog-template';

  // mixins
  import Forms from '@/mixins/forms.js';

  // services
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'AppClientEdit',
    components: {
      'header-view': HeaderView,
      'main-container': MainContainer,
      'main-card': MainCard,
      'simple-dialog-template': SimpleDialogTemplate,
    },
    mixins: [Forms],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        possible_client_types: ['Business', 'Residence'],
        possible_client_status: ['Active', 'Inactive'],
        errorMessage: '',
        errorDialog: false,
        loader: false,
        client: null,
        submitted: false,
      };
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
    },

    async created() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      const res_client = await Clients.getClient(this.uuid, accessToken);

      if (res_client) {
        this.client = res_client;
      }
    },

    methods: {
      async handleSubmit() {
        // check for form errors
        if (!this.$refs.form.validate()) {
          return false;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        const res = await Clients.updateClient(
          this.uuid,
          this.client,
          accessToken
        );

        if (res) {
          // set client to result
          this.client = res;

          // If email/name editing is enabled in the future, handle quickbooks update here

          // route to newly updated client view entry
          this.$router.push({
            name: 'ClientView',
            params: {
              uuid: this.uuid,
            },
          });
        } else {
          this.errorMessage = 'Error updating client';
          this.errorDialog = true;
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
